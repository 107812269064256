import React from 'react'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

const FaceIcon = () => {
  const { isMobile } = useDeviceDetect()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? '32' : '50'}
      height={isMobile ? '32' : '50'}
      viewBox="0 0 50 50"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4221"
            data-name="Rectangle 4221"
            width="50"
            height="50"
            fill="#641d16"
          />
        </clipPath>
      </defs>
      <g id="Group_9610" data-name="Group 9610" transform="translate(0.158 0)">
        <g
          id="Group_9603"
          data-name="Group 9603"
          transform="translate(-0.158 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_6759"
            data-name="Path 6759"
            d="M16.758,7.524H9.535a1.032,1.032,0,1,1,0-2.064h7.222a1.032,1.032,0,1,1,0,2.064"
            transform="translate(19.826 12.665)"
            fill="#641d16"
          />
          <path
            id="Path_6760"
            data-name="Path 6760"
            d="M11.526,19.085a14.379,14.379,0,0,1-5.538-1.111,1.033,1.033,0,1,1,.793-1.907,12.279,12.279,0,0,0,9.477.023A12.312,12.312,0,0,0,22.976,9.4a1.031,1.031,0,1,1,1.9.793,14.473,14.473,0,0,1-13.354,8.89"
            transform="translate(12.654 20.199)"
            fill="#641d16"
          />
          <path
            id="Path_6761"
            data-name="Path 6761"
            d="M5.322,8.4a1.582,1.582,0,0,1-.593-.118A1.551,1.551,0,1,1,6.42,7.949a1.567,1.567,0,0,1-1.1.452m0-1.032h0Z"
            transform="translate(9.07 12.306)"
            fill="#641d16"
          />
          <path
            id="Path_6762"
            data-name="Path 6762"
            d="M24.767,2.9A21.867,21.867,0,1,0,46.635,24.767,21.893,21.893,0,0,0,24.767,2.9m0,46.635A24.767,24.767,0,1,1,49.535,24.767,24.793,24.793,0,0,1,24.767,49.535"
            transform="translate(0.465 0.233)"
            fill="#641d16"
          />
        </g>
      </g>
    </svg>
  )
}

export default FaceIcon
