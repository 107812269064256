import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Box } from '@vtex/store-ui'
import EventPageView from 'src/components/common/EventPageView'
import PageBanner from 'src/components/common/PageBanner'
import InformativeBanner from 'src/components/mascara-legendary/InformativeBanner'
import DoubleImage from 'src/components/mascara-legendary/DoubleImage'
import type { MascaraLegendaryPageQueryQuery } from 'src/pages/mascara-legendary/__generated__/MascaraLegendaryPageQuery.graphql'

type Props = PageProps<MascaraLegendaryPageQueryQuery>

const Above: FC<Props> = ({ data }) => (
  <Box sx={{ background: '#EFC5C0', mt: '-0.6rem', fontFamily: 'Zona Pro' }}>
    <EventPageView
      type="OTHER"
      title={data.cmsInstitutionalPage.seo.siteMetadataWithSlug.title}
    />
    <PageBanner
      {...data.cmsInstitutionalPage?.sections[0].props}
      heightInline={['486px', 'auto']}
      heightBanner={{ height: 550, width: 1920 }}
      plLandingPage
    />
    <InformativeBanner data={data} />
    <DoubleImage />
  </Box>
)

export default Above
