import React from 'react'
import type { ReactNode } from 'react'
import { Flex, Text } from '@vtex/store-ui'

import ClockIcon from './icons/Clock'
import EyeOneIcon from './icons/EyeOne'
import EyeTwoIcon from './icons/EyeTwo'
import FaceIcon from './icons/Face'

interface BenefitsCardsProps {
  benefits: {
    boldText: string
    text?: string
    icon: ReactNode
  }
}

const BenefitsCards = ({ benefits }: BenefitsCardsProps) => {
  const { icon, boldText, text } = benefits

  const switchIcon = () => {
    switch (icon) {
      case 'ClockIcon':
        return <ClockIcon />

      case 'EyeOne':
        return <EyeOneIcon />

      case 'EyeTwo':
        return <EyeTwoIcon />

      case 'FaceIcon':
        return <FaceIcon />

      default:
        return null
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: ['0.375rem', '0.75rem'],
        fontSize: ['0.625rem', '1rem'],
        lineHeight: ['0.75rem', '1.188rem'],
        border: '2px solid #99665E',
        boxShadow: 'inset 0px 0.813rem 1.25rem #8A423B33',
        borderRadius: '1rem',
        width: ['5.563rem', '9.125rem'],
        padding: ['0.25rem 1px', '0.5rem'],
        mt: ['0', '1rem'],
        height: ['7.5rem', 'auto'],
        '& svg': {
          width: ['2rem', '3.125rem'],
          height: ['2rem', '3.125rem'],
        },
      }}
    >
      {switchIcon()}
      <Text
        sx={{ color: '#641D16', textAlign: 'center', wordBreak: 'break-word' }}
      >
        {boldText && <strong>{boldText} </strong>}
        {text && text}
      </Text>
    </Flex>
  )
}

export default BenefitsCards
