import React from 'react'

const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4219"
          data-name="Rectangle 4219"
          width="50"
          height="50"
          fill="#641d16"
        />
      </clipPath>
    </defs>
    <g id="Group_9597" data-name="Group 9597" clipPath="url(#clip-path)">
      <path
        id="Path_6747"
        data-name="Path 6747"
        d="M20.08,19.311a1.527,1.527,0,0,1-.245-.02L8.287,17.316A1.428,1.428,0,0,1,7.1,15.907V5.587a1.429,1.429,0,1,1,2.858,0V14.7l10.36,1.77a1.429,1.429,0,0,1-.238,2.838"
        transform="translate(16.385 9.596)"
        fill="#641d16"
      />
      <path
        id="Path_6748"
        data-name="Path 6748"
        d="M7.978,3.013a.8.8,0,1,0-.8-.8.8.8,0,0,0,.8.8"
        transform="translate(16.556 3.252)"
        fill="#641d16"
      />
      <path
        id="Path_6749"
        data-name="Path 6749"
        d="M10.3,3.365a.8.8,0,1,0-.8-.8.8.8,0,0,0,.8.8"
        transform="translate(21.919 4.064)"
        fill="#641d16"
      />
      <path
        id="Path_6750"
        data-name="Path 6750"
        d="M11.906,4.377a.8.8,0,1,0-.8-.8.8.8,0,0,0,.8.8"
        transform="translate(25.621 6.399)"
        fill="#641d16"
      />
      <path
        id="Path_6751"
        data-name="Path 6751"
        d="M12.984,5.918a.8.8,0,1,0-.8-.8.8.8,0,0,0,.8.8"
        transform="translate(28.108 9.956)"
        fill="#641d16"
      />
      <path
        id="Path_6752"
        data-name="Path 6752"
        d="M13.71,7.755a.8.8,0,1,0-.8-.8.8.8,0,0,0,.8.8"
        transform="translate(29.784 14.195)"
        fill="#641d16"
      />
      <path
        id="Path_6753"
        data-name="Path 6753"
        d="M25,2.927A22.074,22.074,0,1,0,47.073,25,22.1,22.1,0,0,0,25,2.927M25,50A25,25,0,1,1,50,25,25.026,25.026,0,0,1,25,50"
        transform="translate(0 0)"
        fill="#641d16"
      />
    </g>
  </svg>
)

export default ClockIcon
