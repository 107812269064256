import React from 'react'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

const EyeTwoIcon = () => {
  const { isMobile } = useDeviceDetect()

  return (
    <svg
      id="Group_9611"
      data-name="Group 9611"
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? '32' : '50'}
      height={isMobile ? '32' : '50'}
      viewBox="0 0 50 50"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_4222"
            data-name="Rectangle 4222"
            width="50"
            height="50"
            fill="#641d16"
          />
        </clipPath>
      </defs>
      <g id="Group_9606" data-name="Group 9606" clipPath="url(#clip-path)">
        <path
          id="Path_6763"
          data-name="Path 6763"
          d="M25,2.927A22.073,22.073,0,1,0,47.073,25,22.1,22.1,0,0,0,25,2.927M25,50A25,25,0,1,1,50,25,25.026,25.026,0,0,1,25,50"
          transform="translate(0 0)"
          fill="#641d16"
        />
        <g id="noun-eye-4713881" transform="translate(-5.582 -15.663)">
          <path
            id="Path_7051"
            data-name="Path 7051"
            d="M47.976,45.472A4.969,4.969,0,0,1,41.855,44.1,4.787,4.787,0,0,0,40.8,47.16a4.934,4.934,0,1,0,9.867,0,4.787,4.787,0,0,0-1.055-3.06A6.467,6.467,0,0,1,47.976,45.472Z"
            transform="translate(-14.549 -7.274)"
            fill="#641d16"
          />
          <path
            id="Path_7052"
            data-name="Path 7052"
            d="M51.895,39.2c-.475-.422-11.661-10.5-20.789-10.5S10.791,38.725,10.317,39.147a1.163,1.163,0,0,0-.317.791.856.856,0,0,0,.369.739c.475.422,11.608,10.447,20.789,10.447,9.128,0,20.314-10.025,20.789-10.447a.958.958,0,0,0,.317-.739A1.008,1.008,0,0,0,51.895,39.2Zm-39.309.739a57.837,57.837,0,0,1,9.6-6.543,10.976,10.976,0,0,0,0,13.086A57.837,57.837,0,0,1,12.585,39.939Zm18.52,9.023a9.053,9.053,0,0,1-9.023-9.023,9.147,9.147,0,0,1,9.023-9.023,8.675,8.675,0,0,1,4.643,1.319,9.022,9.022,0,0,1-4.643,16.726Zm8.917-2.48a10.938,10.938,0,0,0,2.163-6.543A11.255,11.255,0,0,0,40.076,33.4a57.837,57.837,0,0,1,9.6,6.543A61.252,61.252,0,0,1,40.023,46.482Z"
            fill="#641d16"
          />
        </g>
      </g>
    </svg>
  )
}

export default EyeTwoIcon
