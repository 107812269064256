import React from 'react'
import { Image, Grid, Heading, Flex, Text, SuspenseSSR } from '@vtex/store-ui'
import SingleProductSummary from 'src/components/product/SingleProductSummary'
import Shelf from 'src/components/product/SingleProduct'

import BenefitsCards from '../BenefitsCards'

const benefits = [
  {
    boldText: 'Não borra, não desbota',
    text: 'e não forma grumos ao longo do dia',
    icon: 'ClockIcon',
  },
  {
    boldText: 'Oftalmologicamente testada',
    text: 'Indicada para quem usa lentes de contato',
    icon: 'EyeOne',
  },
  {
    boldText: 'Fácil de remover',
    icon: 'FaceIcon',
  },
  {
    boldText: 'Adequada para olhos sensíveis',
    icon: 'EyeTwo',
  },
]

const InformativeBanner = ({ data }) => {
  const { cmsInstitutionalPage } = data

  return (
    <Flex
      sx={{
        mt: '-3rem',
        padding: ['1.75rem 1rem', '3rem 2.5rem 1rem 0'],
        flexDirection: ['column', 'row'],
        alignItems: ['center', 'unset'],
        justifyContent: ['center'],
      }}
    >
      <Image
        src="/arquivos/batom-mascara-legendary-1.png"
        alt="Legendary Cílios"
        sx={{ mr: '1rem', display: ['none', 'block'], ml: ['', '-0.55rem'] }}
      />
      <Flex
        sx={{
          padding: ['1rem 0', '2.25rem 0'],
          flexDirection: ['column'],
          alignItems: ['center', 'flex-start'],
        }}
      >
        <Text
          sx={{
            color: '#fff',
            backgroundColor: '#D04A9B',
            borderRadius: '0 1rem',
            padding: ['0.438rem 1rem', '0.35rem 1.75rem'],
            fontSize: ['0.688rem', '1.438rem'],
            fontWeight: 'bold',
            width: 'fit-content',
            mt: '1rem',
            mx: ['auto', '0'],
          }}
        >
          LANÇAMENTO
        </Text>
        <Flex
          sx={{
            flexDirection: 'column',
            textTransform: 'uppercase',
            fontSize: ['1.75rem', '2.375rem'],
            mt: '1rem',
            textAlign: ['center', 'left'],
          }}
        >
          <Heading
            sx={{
              backgroundImage:
                'linear-gradient(180deg, #CF4A9A 0%, #5E203C 100%)',
              backgroundClip: 'text',
              fontSize: 'inherit',
              width: ['18.75rem', '37.5rem'],
              fontWeight: '800',
            }}
            css={{
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Conheça todas as Dimensões dos seus cílios
          </Heading>
        </Flex>

        <Text
          sx={{
            color: '#000',
            fontSize: ['0.938rem', '1.375rem'],
            lineHeight: ['', '1.875rem'],
            textAlign: ['center', 'left'],
            mt: '1rem',
            mb: ['0', '1rem'],
            fontWeight: '800',
            width: ['18.75rem', '37rem'],
          }}
        >
          Extensão fio a fio com a nova Legendary Extension é melhor que
          extensão no salão porque:
        </Text>

        <Flex sx={{ width: ['100vw', 'auto'], my: ['-2.5rem', '0'] }}>
          <Image
            src="/arquivos/batom-mascara-legendary-1.png"
            alt="Legendary Cílios"
            sx={{
              display: ['block', 'none'],
              ml: '-1.875rem',
              height: '26.875rem',
              objectFit: 'contain',
            }}
          />
          <Flex
            sx={{
              gap: ['1rem 0.25rem', '0.75rem'],
              flexDirection: ['row'],
              flexWrap: ['wrap', 'nowrap'],
              justifyContent: ['center', 'unset'],
              alignContent: ['center', 'unset'],
            }}
          >
            {benefits.map((benefit) => (
              <BenefitsCards benefits={benefit} key={benefit.text} />
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Grid
        sx={{
          ml: ['0', '1.375rem'],
          width: ['95vw', '20.625rem'],
          background: '#fff',
          placeItems: 'center',
          height: ['unset', '32rem'],
        }}
      >
        <SuspenseSSR fallback={null}>
          <Shelf
            {...cmsInstitutionalPage?.sections[1]?.props}
            ProductSummary={SingleProductSummary}
          />
        </SuspenseSSR>
      </Grid>
    </Flex>
  )
}

export default InformativeBanner
