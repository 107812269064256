import React, { lazy } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import Seo from 'src/views/mascara-legendary/Seo'
import AboveTheFold from 'src/views/mascara-legendary/AboveTheFold'

import type { MascaraLegendaryPageQueryQuery } from './__generated__/MascaraLegendaryPageQuery.graphql'

const loader = () => import('src/views/mascara-legendary/BelowTheFold')
const BelowTheFold = lazy(loader)

export type Props = GatsbyPageProps<MascaraLegendaryPageQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
    preloader: loader,
  },
}

const Page: FC<Props> = (props) => (
  <Layout>
    <View {...ViewComponents} data={props} />
  </Layout>
)

export const querySSG = graphql`
  query MascaraLegendaryPageQuery {
    cmsInstitutionalPage(name: { eq: "Mascara Legendary" }) {
      sections {
        name
        props: data
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
  }
`

export default Page
