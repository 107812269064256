import React from 'react'

const EyeOneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4224"
          data-name="Rectangle 4224"
          width="50"
          height="50"
          fill="#641d16"
        />
      </clipPath>
    </defs>
    <g id="Group_9609" data-name="Group 9609" clipPath="url(#clip-path)">
      <path
        id="Path_6765"
        data-name="Path 6765"
        d="M20.109,24.78a19.556,19.556,0,0,1-16.218-8.67,19.5,19.5,0,0,1,32.436,0,19.5,19.5,0,0,1-16.218,8.67m0-19.383c-8.266,0-14.2,4.045-18.689,10.737,4.492,6.649,10.423,10.694,18.689,10.694S34.349,22.783,38.8,16.134C34.349,9.442,28.375,5.4,20.109,5.4"
        transform="translate(3.277 12.455)"
        fill="#641d16"
      />
      <path
        id="Path_6766"
        data-name="Path 6766"
        d="M11.755,20.016A6.715,6.715,0,1,0,5.04,13.305a6.715,6.715,0,0,0,6.715,6.711"
        transform="translate(11.631 15.201)"
        fill="#641d16"
      />
      <path
        id="Path_6767"
        data-name="Path 6767"
        d="M9.581,13.182a.96.96,0,0,1-.37-.073.989.989,0,0,1-.549-1.29c.714-1.77-.41-2.474-.642-2.6A.993.993,0,0,1,7.6,7.89a.983.983,0,0,1,1.316-.44A3.824,3.824,0,0,1,10.5,12.564a.992.992,0,0,1-.92.619"
        transform="translate(17.281 16.952)"
        fill="#641d16"
      />
      <path
        id="Path_6768"
        data-name="Path 6768"
        d="M37.162,15.136a.9.9,0,0,0-1.343.013,4.4,4.4,0,0,1-5.163,1.168,10.327,10.327,0,0,1-2.709-1.5,7.581,7.581,0,0,0,3.979-2.09,1.088,1.088,0,0,0,.06-1.455.9.9,0,0,0-1.34-.1,5.776,5.776,0,0,1-6.4,1.244,10.2,10.2,0,0,0,6.136-3.966A1.105,1.105,0,0,0,30.222,7a.9.9,0,0,0-1.336.142,8.912,8.912,0,0,1-7.009,3.493,11.414,11.414,0,0,0,4.045-2.908,1.093,1.093,0,0,0-.046-1.455.9.9,0,0,0-1.343.017,10.342,10.342,0,0,1-5.5,3.106,14.748,14.748,0,0,0,4.631-4.538,1.092,1.092,0,0,0-.281-1.432.9.9,0,0,0-1.313.271c-2.124,3.5-6.685,5.478-8.517,6.03a28.85,28.85,0,0,0,3.126-4.5,1.09,1.09,0,0,0-.384-1.406A.9.9,0,0,0,15,4.2c-1.5,2.974-6.4,7.978-7.942,7.816-6.076,3.04-4.108,4.022,2.335,1a11.693,11.693,0,0,1,3.037-.969c8.365-1.4,18.064,6.189,18.1,6.129.023-.04.807.476.827.43a6.678,6.678,0,0,0,2.071.073A5.961,5.961,0,0,0,37.2,16.595a1.093,1.093,0,0,0-.04-1.459"
        transform="translate(7.84 7.479)"
        fill="#641d16"
      />
      <path
        id="Path_6769"
        data-name="Path 6769"
        d="M25,2.927A22.074,22.074,0,1,0,47.073,25,22.1,22.1,0,0,0,25,2.927M25,50A25,25,0,1,1,50,25,25.026,25.026,0,0,1,25,50"
        transform="translate(0 0)"
        fill="#641d16"
      />
    </g>
  </svg>
)

export default EyeOneIcon
