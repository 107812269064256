import React from 'react'
import { Flex, Heading, Image, Text } from '@vtex/store-ui'

const DoubleImage = () => (
  <Flex
    sx={{
      width: '100%',
      justifyContent: ['center', 'space-evenly'],
      alignItems: 'center',
      padding: '0 2rem',
      flexDirection: ['column', 'row'],
      gap: ['1.5rem', '0'],
      maxWidth: '1400px',
      mx: ['0', 'auto'],
      '& img': {
        transition: 'transform 0.2s',
        '&:hover': { transform: ['unset', 'scale(1.15)'] },
      },
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['100%', '25.625rem'],
      }}
    >
      <Image src="/arquivos/tecnologia-xtralong-fx.png" />
      <Heading
        sx={{
          mt: '1.75rem',
          mb: '0.875rem',
          fontWeight: 'bolder',
          fontSize: '1.438rem',
          lineHeight: '1.688rem',
          textAlign: 'center',
        }}
      >
        TECNOLOGIA XTRALONG FX
      </Heading>
      <Text
        sx={{
          textAlign: 'center',
          fontSize: '1.438rem',
          lineHeight: '1.688rem',
        }}
      >
        Fórmula fluida que forma um filme ao redor de cada fio, selando e
        prolongando o tamanho real dos seus cílios.
      </Text>
    </Flex>

    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: ['90%', '25.625rem'],
      }}
    >
      <Image src="/arquivos/aplicador-prolong.png" />
      <Heading
        sx={{
          mt: '28px',
          mb: '14px',
          fontWeight: 'bolder',
          fontSize: '23px',
          lineHeight: '27px',
          textAlign: 'center',
        }}
      >
        APLICADOR PROLONG
      </Heading>
      <Text
        sx={{
          textAlign: 'center',
          fontSize: '1.438rem',
          lineHeight: '1.688rem',
        }}
      >
        Design exclusivo que proporciona efeito de precisão e definição. Cerdas
        espaçadas que garantem fios mais espessos e volumosos.
      </Text>
    </Flex>
  </Flex>
)

export default DoubleImage
