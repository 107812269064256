import { Box, Center, Shelf, Spinner } from '@vtex/store-ui'
import React from 'react'
import { gql } from '@vtex/gatsby-plugin-graphql'
import { useQuery } from '@vtex/gatsby-theme-store'
import type { ComponentPropsWithoutRef, FC } from 'react'

import { BrowserShelfQuery } from './__generated__/BrowserShelfQuery.graphql'
import type {
  BrowserShelfQueryQuery,
  BrowserShelfQueryQueryVariables,
} from './__generated__/BrowserShelfQuery.graphql'

type Props = ComponentPropsWithoutRef<typeof Shelf>

const pageSizes = [1]

const ShelfProducts: FC<Props> = (props) => {
  const { searchParams } = props

  const { data } = useQuery<
    BrowserShelfQueryQuery,
    BrowserShelfQueryQueryVariables
  >({
    ...BrowserShelfQuery,
    variables: searchParams,
  })

  if (data === undefined) {
    return (
      <Box sx={{ height: ['200px', '300px'] }}>
        <Center>
          <Spinner />
        </Center>
      </Box>
    )
  }

  return (
    <Shelf
      {...props}
      products={data?.vtex.products ?? []}
      pageSizes={pageSizes}
    />
  )
}

export const query = gql`
  query BrowserShelfQuery(
    $from: Int!
    $to: Int!
    $collection: String!
    $orderBy: String
    $hideUnavailableItems: Boolean!
  ) {
    vtex {
      products(
        from: $from
        to: $to
        collection: $collection
        orderBy: $orderBy
        hideUnavailableItems: $hideUnavailableItems
      ) {
        ...ProductSummary_product
      }
    }
  }
`

export default ShelfProducts
